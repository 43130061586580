<template>
  <div class="">
    <PageTitle title="" btn="新增" :hideBtn="hideCreateBtn" @btnClick="modal.warning = true" />
    <BaseTable v-loading="loading" :data="displayData">
      <BaseElTableColumn label="名稱" prop="name" align="center" />
      <BaseElTableColumn label="單位" prop="unitName" align="center" />
      <BaseElTableColumn label="有效日期" prop="displayAnnualSettlementDate" align="center" />
      <BaseElTableColumn label="到期通知發送天數" prop="daysDiffExpirationNotify" align="center" />

      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <EditPointModal
      v-if="modal.edit"
      :data="selectRow"
      @close="onCloseEdit"
      @refresh="refresh"
    />
    <WarningDialog
      v-if="modal.warning"
      title="新增點數"
      @confirm="onConfirmCreate"
      @close="modal.warning = false"
    >
      <template slot="body">
        <div style="padding-top: 16px">
          點數錢包建立後，<b>無法刪除</b>且<b>類型無法修改</b>。請確認設定無誤後再送出。
        </div>
      </template>
    </WarningDialog>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import EditPointModal from './EditPointModal.vue'
import { GetShopPointList } from '@/api/shopPoint'
import { useShop } from '@/use/shop'
import { map, get } from 'lodash'
import store from '@/store'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'

export default defineComponent({
  name: 'PointList',
  components: { EditPointModal, WarningDialog },
  setup (props) {
    const { shopId } = useShop()
    const tableData = ref([])
    const loading = ref(false)
    const modal = reactive({
      edit: false,
      warning: false,
    })

    const hideCreateBtn = computed(() => {
      return tableData.value.length >= 2
    })

    const selectRow = ref(null)

    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        return {
          ...item,
          displayAnnualSettlementDate: get(item, 'annualSettlementDate') || '-',
        }
      })
    })

    const onRowEdit = (data) => {
      selectRow.value = data
      modal.edit = true
    }

    const getShopPointList = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await GetShopPointList({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      tableData.value = res
    }

    const onCloseEdit = () => {
      modal.edit = false
      selectRow.value = null
    }
    const onConfirmCreate = () => {
      modal.warning = false
      modal.edit = true
    }
    const refresh = async () => {
      getShopPointList()
      store.dispatch('shop/getGlobalShopData', { shopId: shopId.value })
    }

    onBeforeMount(async () => {
      await getShopPointList()
    })

    return {
      displayData,
      modal,
      selectRow,
      onRowEdit,
      onCloseEdit,
      onConfirmCreate,
      loading,
      getShopPointList,
      hideCreateBtn,
      refresh
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
