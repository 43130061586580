export const businessTimeOptions = [
  { label: 'businessTimeSettings.24hours.title', value: '24Hours' },
  { label: 'businessTimeSettings.customWeek.title', value: 'CustomWeek' },
]
export const weeksOptions = [
  { label: 'common.weeksOptions.mon', value: 1 },
  { label: 'common.weeksOptions.tue', value: 2 },
  { label: 'common.weeksOptions.wed', value: 3 },
  { label: 'common.weeksOptions.thu', value: 4 },
  { label: 'common.weeksOptions.fri', value: 5 },
  { label: 'common.weeksOptions.sat', value: 6 },
  { label: 'common.weeksOptions.sun', value: 0 },
]

export const monthOptions = [
  { label: 'common.monthOptions.jan', value: 1 },
  { label: 'common.monthOptions.feb', value: 2 },
  { label: 'common.monthOptions.mar', value: 3 },
  { label: 'common.monthOptions.apr', value: 4 },
  { label: 'common.monthOptions.may', value: 5 },
  { label: 'common.monthOptions.jun', value: 6 },
  { label: 'common.monthOptions.jul', value: 7 },
  { label: 'common.monthOptions.aug', value: 8 },
  { label: 'common.monthOptions.sep', value: 9 },
  { label: 'common.monthOptions.oct', value: 10 },
  { label: 'common.monthOptions.nov', value: 11 },
  { label: 'common.monthOptions.dec', value: 12 },
]
